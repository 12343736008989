window.$ = window.jQuery = require('jquery'); // required for bootstrap
window.Popper = require('popper.js'); // required for tooltip, popup...

import 'bootstrap';
import 'slick-carousel';

import './modules';

var MYLANGUAGEKEY = 'languageId';
var STOREIDKEY = 'storeId';
var STORECODE = 'storeCode';
var STORENAMEKEY = 'storeName';
var STOREINFOKEY = 'storeInfo';
var STORECLOSUREHOURS = 'storeClosureHours'
var lang = getCookie(MYLANGUAGEKEY);

var d = new Date();
var currentDay = d.getDay();
var currentTime = d.getHours()+':'+d.getMinutes();

/*<![CDATA[*/
var defaultLanguage = /*[[${languageDefault}]]*/ 'fr';
/*]]>*/


$('document').ready(function(){
    var lang = getCookie(MYLANGUAGEKEY);
    document.querySelector('meta[property="og:title"]').setAttribute("content", document.title);
    if (document.querySelector('meta[name="description"]') !== null) {
        document.querySelector('meta[property="og:description"]').setAttribute("content",document.querySelector('meta[name="description"]').getAttribute("content"));
    }
    showMyStore();
    buildwebsiteJSONLD()

    // This function pushes values to the dataLayer to trigger a Google Analytics event tag.

    //SET DEFAULT LANG
    if (lang == null) {
        var navLang = navigator.language;
        lang = navLang.substring(0, 2);
    }

    if ($('#flipp-container').length) {
        var myStoreCode = getCookie('storeCode');
        $('#flipp-store-code').val(myStoreCode);

        var flippContainer = document.querySelector("#flipp-container");
        var storefront = new Flipp.Storefront({
            accessToken: "7c9a2c8409a452ec8e9e54806370af8e",
            merchantId: "3761",
            merchantNameIdentifier: "marcheami",
            storeCode: myStoreCode,
            locale: lang+'-CA'
        });
        storefront.renderStorefront(flippContainer);
    }

    // STYLEGUIDE

    if ($('body').hasClass('styleguide')) {
        console.log('You are in the styleguide')
    }

    $(document).on('click', '.setStore', function(){
        setStore($(this));
    });
    function setStore(element) {
        var storeId = $(element).data('store-id');
        setCookie(STOREIDKEY, storeId);
        setCookie(STORECODE, $(element).data('store-code'));
        setCookie(STORENAMEKEY, $(element).data('store-name'));
        setCookie(STOREINFOKEY, $(element).data('store-info'));
        for(let day in $(element).data('store-closurehours')){
            if(day == currentDay){
                var closingHour = $(element).data('store-closurehours')[day].split(' - ')[1];
                setCookie(STORECLOSUREHOURS, closingHour);
            }
        }
        $(element).removeClass("btn-choisir");
        $(element).addClass('btn-choisir-active');
        $('.search-stores-map').find("[data-store-id='" + storeId + "']").removeClass("btn-choisir");
        $('.search-stores-map').find("[data-store-id='" + storeId + "']").addClass('btn-choisir-active');
        if(lang == "en")
        {
            $(element).text("My store");
            $('.search-stores-map').find("[data-store-id='" + storeId + "']").text("My store");
        }else{
            $(element).text("Mon magasin");
            $('.search-stores-map').find("[data-store-id='" + storeId + "']").text("Mon magasin");
        }
        $('.setStore').each(function() {
            if ($(this).data('store-code') != getCookie(STORECODE)) {
                var storeIdNotSelected = $(this).data('store-code');
                $(this).removeClass('btn-choisir-active');
                $('.search-stores-map').find("[data-store-id='" + storeIdNotSelected + "']").removeClass('btn-choisir-active');
                $(this).addClass('btn-choisir');
                $('.search-stores-map').find("[data-store-id='" + storeIdNotSelected + "']").addClass('btn-choisir');
                if(lang == "en")
                {
                    $(this).text("Select");
                    $('.search-stores-map').find("[data-store-id='" + storeIdNotSelected + "']").text("Select");
                }else{
                    $(this).text("Choisir");
                    $('.search-stores-map').find("[data-store-id='" + storeIdNotSelected + "']").text("Choisir");
                }

            }
        });
        push_event_to_google_analytics_favorite_store($(this).data('store-name'));
        showMyStore();
    }

    //STORE SELECTION COOKIE
    $('.setStore').each(function() {
        if ($(this).data('store-code') == getCookie(STORECODE)) {
            $(this).removeClass("btn-choisir");
            $(this).addClass('btn-choisir-active');
            if(lang == "en")
            {
                $(this).text("My store");
            }else{
                $(this).text("Mon magasin");
            }
        }
    });

    // CAREER FORM - CV
    var $resume = $('#resume');
    if ($resume.length > 0) {
        $resume.on('change', function () {
            //get the file name
            var fileName = $(this).val();
            //replace the "Choose a file" label
            $(this).next('.custom-file-label').html(fileName);
        });
    }

    //FLIPP FEATURED PRODUCTS
    var $featuredProducts = $('#featured-products');
    if ($featuredProducts.length > 0){
        var lang = getCookie(MYLANGUAGEKEY);
        if(lang == null) {
            lang = 'fr';
        }
        var myStoreCode = getCookie('storeCode');

        //Appel pour les produits en vedette
        $.ajax({
            type: 'GET',
            url: 'https://api.flipp.com/flyerkit/v4.0/publications/marcheami',
            data: {
                access_token: '7c9a2c8409a452ec8e9e54806370af8e',
                locale: lang+'-CA',
                store_code: myStoreCode,
                see_future: false,
                page: 1
            },
            dataType: 'json',
            success: function (data) {
                if (data[0] != undefined && data[0].flyer_type == 'circulaire') {
                    setCookie('flyerId', data[0].id);
                } else if (data[1] != undefined && data[1].flyer_type == 'circulaire') {
                    setCookie('flyerId', data[1].id);
                }

                //Call to get the flyer's associated products
                callFlippForProducts(1, myStoreCode);
            },
            error: function (){
                $(".featured-products__wrapper").hide();
            }
        });
    }

    //TOOLTIPS
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    //LANGUAGE SWITCHER
    $(function () {
        $('[data-lang]').each(function() {
            $(this).on('click', function(e) {
                setCookie(MYLANGUAGEKEY, $(this).data('lang'));
            });
        })
    });

    //FOCUS ON FIRST FIELD OF A FORM IF ERRORS
    $('form').find('.error').first().parent().find('.form-control').focus();

    //FILTERS FOR RECIPIES
    $('label').on('click', function (e) {
        var selectedOption = $(this).find('input').attr('name');
        $('#recipes .recipe').each(function () {
            if (selectedOption === 'all' || $(this).hasClass(selectedOption)) {
                $(this).removeClass('d-none').addClass('d-flex');
            } else {
                $(this).removeClass('d-flex').addClass('d-none');
            }
        });

    });

    var myLanguage = getCookie(MYLANGUAGEKEY);
    if(!myLanguage){
        myLanguage = defaultLanguage;
    }

    //MAILCHIMP SUBMISSION AJAX
    var $form = $('#mc-embedded-subscribe-form');

    if ($form.length > 0) {
        $('#mc-embedded-subscribe-form').on('submit', function (e) {
            if (e) e.preventDefault();
            if(formIsValid($(this))){
                register($form);
            }
        });
    }
});

function setCookie(cookieKey, cookieValue) {
    var d = new Date();
    d.setTime(d.getTime() + 10*365*24*60*60*1000); //10 years
    document.cookie = cookieKey + '=' + cookieValue + ';path=/ ;expires='+d.toGMTString()+';'
}

function getCookie(cookieKey) {
    var cookieKey = cookieKey + '=';
    var ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieKey) == 0) return c.substring(cookieKey.length, c.length);
    }
}

function showMyStore() {
    var myStoreId = getCookie('storeId');
    var myStoreCode = getCookie('storeCode');
    var myStoreName = getCookie('storeName');
    var myStoreInfo = getCookie('storeInfo');
    var myStoreClosingHour = getCookie('storeClosureHours');

    if (myStoreName == null || myStoreName == 'undefined') {
        //SET DEFAULT QUEBEC STORE
        myStoreId = '1';
        myStoreCode = '3245';
        myStoreName = 'LES ENTREPRISES HENRY JENNISS';
        myStoreInfo = 'B.P. 62, LA ROMAINE - 418 229-2944';

        setCookie(STOREIDKEY, myStoreId);
        setCookie(STORECODE, myStoreCode);
        setCookie(STORENAMEKEY, myStoreName);
        setCookie(STOREINFOKEY, myStoreInfo);
    }
        lang = document.documentElement.lang;
        /* on ne vérifie pas la date donc elle n'est pas important*/
        if(Date.parse('01/01/2020 '+currentTime) < Date.parse('01/01/2020 '+myStoreClosingHour)){
            var str = myStoreClosingHour.split(":");
            if(str[1] == "00"){
                myStoreClosingHour = str[0];
            }
            if(lang == 'fr') {
                var myStore = '<strong>' + myStoreName + '</strong>  ' + myStoreInfo + '<p><strong>Ouvert jusqu’à ' + myStoreClosingHour + 'h</strong></p>';
            } else {
                var myStore = '<strong>' + myStoreName + '</strong>  ' + myStoreInfo + '<p><strong>Open till ' + myStoreClosingHour + 'h</strong></p>';

            }
        } else {
            if(lang == 'fr') {
                var myStore = '<strong>' + myStoreName + '</strong>  ' + myStoreInfo + '<p><strong>Fermé</strong></p>';
            } else {
                var myStore = '<strong>' + myStoreName + '</strong>  ' + myStoreInfo + '<p><strong>Closed</strong></p>';
            }
        }

    //INJECT STORE INFORMATION ON HEADER
    $('#selectedStoreInfo').html(myStore);
}

function register($form) {
    var languageSelection = getCookie(MYLANGUAGEKEY);

    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",

        error: function (err) {
            let errorMessage = 'Something went wrong. Please try later!';

            if (languageSelection == 'fr') {
                errorMessage = 'Nous éprouvons des problèmes. Veuillez réessayer plus tard !'
            }

            $('#mc-msg').append(errorMessage).addClass('alert alert-danger');
        },

        success: function (data) {
            if (data.result != "success") {
                //Show warning message
                let msg = data.msg;
                let msgTranslated = false;
                let mcMessages = {
                    'We have sent you a confirmation email': 'Nous vous avons envoyé un courriel de confirmation',
                    'Please enter a value': 'S\'il vous plaît, entrez une valeur',
                    'An email address must contain a single @': 'Une adresse courriel doit contenir un seul @',
                    'The domain portion of the email address is invalid (the portion after the @: )': 'La partie domaine de l\'adresse courriel n\'est pas valide (la partie après le @ :)',
                    'The username portion of the email address is empty': 'La partie nom d\'utilisateur de l\'adresse courriel n\'est pas valide (la partie avant le @ :)',
                    'This email address looks fake or invalid. Please enter a real email address': 'Ce courriel semble faux ou non valide. S\'il vous plaît, entrez un courriel valide'
                };

                //add target blank if message contain a link
                msg = msg.replace('<a href', ' <a target="_blank" href');

                //highlight field with error
                let errorField = msg.substring(0, msg.indexOf(' - '));
                $('input.required:eq(' + errorField + ')').focus();

                if (languageSelection == 'fr') {
                    for (let key in mcMessages) {
                        if (msg.indexOf(key) >= 0) {
                            msg = mcMessages[key];
                            msgTranslated = true;
                        }
                    }

                    if (msgTranslated) {
                        msg = msg.replace('is already subscribed to list', 'est déjà inscrit dans la liste');
                        msg = msg.replace('Click here to update your profile', 'Cliquez ici pour mettre vos informations à jour');
                    }
                } else {
                    msg = msg.substring(msg.indexOf(' - ') + 3);
                }

                $('#mc-msg').html(msg).addClass('alert alert-warning');
            }

            else {
                //Show success message and hide modal after.

                if (languageSelection == 'en') {
                    data.msg = 'Thank you for your subscription!';
                } else {
                    data.msg = 'Merci pour votre inscription !';
                }

                $('#mc-msg').text(data.msg).removeClass().addClass('alert alert-success').next().detach();


                setTimeout(function () {
                    $('#newsletterForm').modal('hide');
                }, 2000);

            }
        }
    });
}

function formIsValid(form) {
    let validation = true;
    form.find('.required').each(function(){
        if (($(this).val()).length <= 0) {
            validation = false;
        }
    });

    return validation;
}

function buildwebsiteJSONLD(){
    $("#websiteJSONLD").text(function() {
        return JSON.stringify({
            "@context": "http://www.schema.org",
            "@type": "WebSite",
            "name": "Marché Ami",
            "url": "https://marcheami.ca"
        });
    });
}

function callFlippForProducts(page, flyerStoreCode) {
    var products;
    $.ajax({
        type: 'GET',
        url: 'https://api.flipp.com/flyerkit/v4.0/publication/' + getCookie('flyerId') +'/products',
        data: {
            access_token: '7c9a2c8409a452ec8e9e54806370af8e',
            locale: lang+'-CA',
            store_code: flyerStoreCode,
            page: page,
            size: 5
        },
        dataType: 'json',
        success: function (data) {
            products = data;
            if (products != 'undefined' && products.length < 1) {
                callFlippForProducts(page+1, flyerStoreCode);
            } else if (products != 'undefined') {
                displayProducts(data);
            }

        },
        error: function (){
            $(".featured-products__wrapper").hide();
        },
        complete: function() {
            if (products != 'undefined' && products.length > 0) {
                createCarroussel();
            }
        }
    });
}

function displayProducts(data) {

    var currentDay = Date.now();
    var productsCode = [];

    $.each(data, function(index, element) {
        var validFrom = new Date(element.valid_from);

        //Afficher que les produits vedettes sont bien actifs et cacher les rabais à venir
        if(currentDay > validFrom.getTime() && productsCode.indexOf(element.id) < 0) {
            productsCode.push(element.id);

            var productTemplate = $('#product-template').html();
            var productTemplateSr = $('#product-template-sr').html();
            var productName =  element.name;

            var replacePrePricePart2 = element.pre_price_text;
            var replacePrePrice = element.pre_price_text;
            if(replacePrePrice.split(" ").length > 1) {
                if(lang == 'en') {
                    replacePrePricePart2 = replacePrePrice.split(" ")[1].replace("pour", "for");
                    replacePrePrice = replacePrePrice.split(" ")[0] + " " + replacePrePricePart2;
                }
            }


            // split the lb and kg prices
            var productTextLb =  element.post_price_text;
            var delimiter = '/';
            var start = 2;
            var tokens = productTextLb.split(delimiter).slice(start);
            var dollarDiscount, percentDiscount;
            if(productTextLb.split(delimiter).length > 1) {
                productTextLb = productTextLb.split(delimiter)[0] + delimiter + productTextLb.split(delimiter)[1];
            }

            if( lang != 'undefined' && lang == "fr") {
                productName =  productName.split("|")[0];
                dollarDiscount = '$ de rabais';
                percentDiscount = '% de rabais';
            } else if (lang != 'undefined' && lang == "en") {
                productName =  productName.split("|")[1] != undefined ? productName.split("|")[1] : productName.split("|")[0];
                dollarDiscount = 'Discount of ';
                percentDiscount = 'Discount of ';
            }


            productTemplate = productTemplate.replace(new RegExp('__DESKTOP_IMG__', 'g'), element.medium_image_url);
            productTemplate = productTemplate.replace(new RegExp('__ALT__', 'g'), productName);
            productTemplate = productTemplate.replace(new RegExp('__TITLE__', 'g'), productName);
            productTemplateSr = productTemplateSr.replace(new RegExp('__TITLE__', 'g'), productName);
            try{
                if (element.price_text != null && element.price_text != '') {
                    productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '$');
                    productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.price_text * 100) / 100).toFixed(2));
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.price_text * 100) / 100).toFixed(2));
                } else if (element.dollars_off != null && element.dollars_off != '') {
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.dollars_off * 100) / 100).toFixed(2));
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.dollars_off * 100) / 100).toFixed(2));
                    if( lang != 'undefined' && lang == "fr") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), dollarDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    } else if (lang != 'undefined' && lang == "en") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), dollarDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '$');
                    }
                } else if (element.percent_off != null && element.percent_off != '') {
                    if( lang != 'undefined' && lang == "fr") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), percentDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    } else if (lang != 'undefined' && lang == "en") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), percentDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '%');
                    }
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.percent_off);
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.percent_off);
                }
            }catch(err){
                productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '');
                productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.price_text);
                productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.price_text);
            }

            productTemplate = productTemplate.replace(new RegExp('__UNIT__', 'g'), productTextLb);
            productTemplateSr = productTemplateSr.replace(new RegExp('__UNIT__', 'g'), productTextLb);

            $('.featured-products__list').append(productTemplate);
            $('#featured-products-sr').append(productTemplateSr);
        }
    });
}

function createCarroussel() {
    //Création du carrousel des produits vedettes
    $('#featured-products').slick({
        infinite: true,
        speed: 1000,
        dots: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false
                }
            },
            {
                breakpoint: 575,
                settings: {
                    dots: false,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    });

    var $status = $('.paging-info');
    var $slickElement = $('#featured-products');

    $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text((i) + '/' + (slick.slideCount));
    });
}